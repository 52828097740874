import "./RadioButton.scss";

import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";
import {
  FieldErrors,
  FieldValue,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";

export interface RadioButtonProps<TFormValues extends FieldValues> {
  id?: string;
  className?: string;
  name: Path<TFormValues>;
  value: string | number;
  label?: string;
  labelComponent?: ReactNode;
  options?:
    | (RegisterOptions & { readOnly?: boolean })
    | (Omit<RegisterOptions, "disabled"> & {
        readOnly?: boolean;
        disabled: false;
      });
  errors: FieldErrors;
  disabled?: boolean;
  register: UseFormRegister<TFormValues>;
  defaultChecked?: boolean;
}

export const RadioButton = <TFormValues extends FieldValues>({
  id,
  className,
  name,
  value,
  label,
  labelComponent,
  options = { required: true },
  errors,
  register,
  defaultChecked,
}: RadioButtonProps<TFormValues>) => {
  const uid = id ?? `${name}-${value}`;

  return (
    <div className={clsx("radio-button-container", className)}>
      <div className="input-wrapper">
        <input
          // This cannot be options.disabled, as this would pass the option into ...register above,
          // which then removes any value from this field.
          {...(options.readOnly && { disabled: true })}
          id={uid}
          className={clsx("min-w-max", errors[name] && "error")}
          type="radio"
          value={value}
          aria-label={label}
          data-testid={uid}
          tabIndex={0}
          {...register(name, options as RegisterOptions<TFormValues>)}
        />
        <div className="radio-button" />
      </div>
      <label
        htmlFor={uid}
        className={clsx(
          "label",
          (options?.disabled || options?.readOnly) && "opacity-20",
        )}
      >
        {label}
        {labelComponent}
      </label>
    </div>
  );
};

export interface RadioButtonOptionProps<TFormValues extends FieldValues> {
  name: Path<TFormValues>;
  label: string;
  value: FieldValue<TFormValues>;
  disabled?: boolean;
}

export const RadioButtonOption = <TFormValues extends FieldValues>({
  name,
  label,
  value,
  disabled = false,
}: RadioButtonOptionProps<TFormValues>) => {
  const uid = `${name}-${value}`;

  return (
    <RadioGroup.Option
      className="radio-button-option"
      id={uid}
      value={value}
      disabled={disabled}
      data-testid={uid}
    >
      {({ checked }) => (
        <div
          className={clsx(
            "radio-button-option-container",
            checked && "checked",
          )}
        >
          <div className="radio-button" />
          <label htmlFor={uid}>{label}</label>
        </div>
      )}
    </RadioGroup.Option>
  );
};
